import styled from "styled-components/macro";

import media from "Gfx/media";

export interface TitleProps {
  size?: "large" | "upper-medium" | "medium" | "small";
  textAlign?: "center" | "left" | "right" | "justify";
  margin?: string;
  color?: string;
}

const Title = styled.h1<TitleProps>`
  font-weight: 600;
  line-height: 1.25;
  font-size: ${props =>
    props.size === "small" ? 24 : props.size === "medium" ? 30 : props.size === "upper-medium" ? 34 : 44}px;
  letter-spacing: ${props => (props.size === "small" ? 0.38 : props.size === "medium" ? 0.5 : 0.75)}px;
  text-align: ${props => (props.textAlign ? props.textAlign : "inherit")};
  margin: ${props => (props.margin ? props.margin : `25px 0`)};
  color: ${props => props.color && props.color};
  ${media.phone`
    font-size: 30px;
 
  `}
`;

export default Title;
