const constants = {
  colorPrimary: "#37383D",
  colorSecondary: "#3741F5",
  colorBg: "#fff",
  colorPortage: "#8e95f9",
  colorScrollMystic: "#dee5eb",
  colorScrollCoolGray: "#8390a9",
  colorNotification: "#324893",
  colorNotificationBg: "#F5F5FF",
  colorNotificationWarningBg: "#FFF7D6",
  colorSunglow: "#ffd729",
  colorHotToddy: "#ac8c01",
  colorNotificationSuccessBg: "#009173",
  colorNotificationFailureBg: "#E64C51",
  colorNotificationInfoBg: "#D4E9FF",
  colorNotificationWarning: "#37383D",
  colorTextPrimary: "#37383D",
  colorGreyBlue: "#49588C",
  colorTextBold: "#23232E",
  colorTextSecondary: "#3741F5",
  colorTextTertiary: "#61626b",
  colorTextDark: "#231F20",
  colorLinkDisabled: "#8A94A6",
  colorBorder: "#C4D0DA",
  colorBorderSuccess: "#00BA93",
  colorBorderError: "#E83B19",
  colorBorderPending: "#FFD728",
  colorBorderInfo: "#4FA3FF",
  colorTextInverted: "#fff",
  colorDisabled: "#324893",
  colorSuccess: "#00BA93",
  colorChambray: "#4f5d78",
  colorAliceBlue: "#f0faff",
  colorPending: "#997E09",
  colorError: "#AB3E3A",
  colorErrorAlternative: "#EB001B",
  colorBgDisabled: "#D7E0FF",
  colorBgPending: "#FFF7D6",
  colorBgSuccess: "#DAF7F1",
  colorBgFailed: "#FFD8D6",
  colorBgFailedNew: "#FFEEED",
  colorBgFailedRejection: "#FFEEED80",
  colorFocused: "#949498",
  colorInProgress: "#8C939E",
  colorPalatinateBlue: "#3841f5",
  colorDocumentBackground: "#f0faff",
  colorTooltipBackground: "#1D115E",
  colorTooltipIconBackground: "#90a4e9",
  colorFieldLabelAlternative: "#8A94A6",
  colorQuartz: "#dfdff2",
  colorPanelBg: "#F9F9F9",
  fontFamilySourceSansPro: "Source Sans Pro, sans-serif",
  maxWidthMainContent: 1200,
  horizontalPaddingDesktop: 20,
  horizontalPaddingMobile: 10,
  formNarrowWidth: 325,
  formMiddleWidth: 400,
  formWideWidth: 800,
  dashboardMaxWidth: 840,
  formMostWideWidth: 1200,
  platformCellWidthNormal: 100,
  verificationPanelPaddingDesktop: 16,
  verificationPanelPaddingMobile: 16,
  borderRadius: "8px",
  boxShadowModal: "0px 4px 8px rgba(55, 56, 61, 0.10159)",
};

export default constants;
