import React from "react";
import styled from "styled-components/macro";
import { AuthenticationStatus } from "vo-validator/build/schema";

import constants from "Gfx/constants";
import media from "Gfx/media";

import Logo from "./Logo";
import Navigation from "./Navigation";

export interface HeaderProps {
  authenticationStatus: AuthenticationStatus;
}

const Header: React.FC<HeaderProps> = ({ authenticationStatus }) => {
  return (
    <HeaderWrap>
      <Content>
        <Logo isLoggedIn={authenticationStatus.isLoggedIn} />
        {renderMenu()}
      </Content>
    </HeaderWrap>
  );

  function renderMenu() {
    if (authenticationStatus.isLoggedIn) {
      return <Navigation isUserAuthenticated userEmail={authenticationStatus.email || undefined} />;
    }

    return <Navigation />;
  }
};

const Content = styled.div`
  box-sizing: content-box;
  height: 100%;
  max-width: ${constants.maxWidthMainContent}px;
  margin: auto;
  padding: 0 ${constants.horizontalPaddingDesktop}px;
  display: flex;
  align-items: center;
  text-align: center;

  ${media.phone`
		padding: 0 ${constants.horizontalPaddingMobile}px;
  `}

  > * {
    box-sizing: border-box;
  }
`;

const HeaderWrap = styled.div`
  height: 80px;
  box-shadow: 0 3px 5px 0 rgb(100 100 100 / 40%);
  z-index: 1;
  background: white;
`;

export default Header;
