import React from "react";
import { Redirect } from "react-router";
import { Route, Switch } from "react-router-dom";

import Loader from "Components/loader/Loader";
import NotFoundView from "Views/NotFoundView";

import { useSettingsQuery } from "../../schema";

const VerificationsDashboardView = React.lazy(() => import("./VerificationsDashboardView"));
const ProfileView = React.lazy(() => import("./ProfileView"));
const PlatformsView = React.lazy(() => import("./PlatformsView"));
const LogoutView = React.lazy(() => import("./LogoutView"));
const IntegratorView = React.lazy(() => import("./IntegratorView"));
const TransactionView = React.lazy(() => import("./TransactionView"));
// const PaymentStatusView = React.lazy(() => import("./PaymentStatusView"));

interface RouteProps {
  location: {
    state?: {
      transactionId?: string | undefined;
    };
  };
}

const PrivateView: React.FC = () => {
  const { loading } = useSettingsQuery({ nextFetchPolicy: "cache-first" });

  if (loading) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route path="/" exact={true}>
        <Redirect to="/verifications" />
      </Route>
      <Route path="/verifications" component={VerificationsDashboardView} />
      <Route path="/platforms" component={PlatformsView} />
      <Route path="/integrator/:integratorId?" component={IntegratorView} />
      <Route path="/transaction" component={TransactionView} />
      {/*<Route path="/payment-status" component={PaymentStatusView} />*/}

      <Route
        path={["/", "/verify-email"]}
        exact={true}
        render={routeProps => {
          return (routeProps as RouteProps).location.state?.transactionId ? (
            <Redirect to="/transaction" />
          ) : (
            <Redirect to="/" />
          );
        }}
      />
      <Route path="/logout" exact={true} component={LogoutView} />
      <Route path="/profile" exact={true} component={ProfileView} />

      <Route component={NotFoundView} />
    </Switch>
  );
};

export default PrivateView;
