import React, { useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch, useLocation, withRouter } from "react-router-dom";
import useHotjar from "react-use-hotjar";
import { useIntercom } from "react-use-intercom";
import styled from "styled-components/macro";

import ErrorBoundary from "Components/error-boundary/ErrorBoundary";
import Header from "Components/header/Header";
import Loader from "Components/loader/Loader";
import GlobalStyle from "Gfx/main";
import { parseClientConfig, ClientConfig } from "Services/clientConfigHelper";
import PrivateView from "Views/private/PrivateView";
import PublicView from "Views/public/PublicView";

import { isTransactionFlowUrl } from "./Services/integratorFlow";
import { useAuthenticationStatusQuery, useClientConfigQuery } from "./schema";

const ResetPasswordView = React.lazy(() => import("Views/ResetPasswordView"));
const VerifyTransactionView = React.lazy(() => import("Views/VerifyTransactionView"));

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > * {
    max-width: 100%;
  }
`;

export const ClientConfigContext = React.createContext<ClientConfig>({} as ClientConfig);

const App: React.FC<RouteComponentProps> = () => {
  const [hotjarInitialized, setHotjarInitialized] = useState<boolean>(false);
  const { data: clientConfigData } = useClientConfigQuery();
  const { data: authenticationStatusData } = useAuthenticationStatusQuery();
  const { boot, shutdown } = useIntercom();
  const { initHotjar, stateChange } = useHotjar();
  const isTransactionFlow = isTransactionFlowUrl();
  const location = useLocation();

  useEffect(() => {
    if (hotjarInitialized) {
      stateChange(location.pathname.slice(1));
    } else {
      setHotjarInitialized(initHotjar(Number(process.env.REACT_APP_HOTJAR_ID) || 1, 6));
    }
  }, [initHotjar, stateChange, hotjarInitialized, location]);

  useEffect(() => {
    const authenticationStatus = authenticationStatusData?.authenticationStatus;
    if (isTransactionFlow || authenticationStatus?.isLoggedIn || ["/", "/signup"].includes(location.pathname)) {
      const userEmail = authenticationStatus?.email;
      boot({ ...(userEmail && { email: userEmail }) });
    } else {
      shutdown();
    }
  }, [boot, shutdown, isTransactionFlow, authenticationStatusData, location]);

  if (!authenticationStatusData) {
    return <Loader />;
  }

  return (
    <ErrorBoundary>
      <ClientConfigContext.Provider value={parseClientConfig(clientConfigData?.clientConfig)}>
        <GlobalStyle />
        <Wrap>
          <React.Suspense fallback={<Loader overlay />}>
            <Header authenticationStatus={authenticationStatusData.authenticationStatus} />
          </React.Suspense>
          <React.Suspense fallback={<Loader overlay />}>
            <Switch>
              <Route path="/verify/:transactionId" exact={true} component={VerifyTransactionView} />
              <Route path="/reset-password/:passwordResetId" exact={true} component={ResetPasswordView} />
              {authenticationStatusData.authenticationStatus.isLoggedIn ? <PrivateView /> : <PublicView />}
            </Switch>
          </React.Suspense>
        </Wrap>
      </ClientConfigContext.Provider>
    </ErrorBoundary>
  );
};

export default withRouter(App);
