import React, { CSSProperties, ReactNode } from "react";
import styled from "styled-components/macro";

import constants from "Gfx/constants";
import media from "Gfx/media";

export type ViewVariant =
  | "blank"
  | "full-width"
  | "form-middle"
  | "form-narrow"
  | "form-wide"
  | "form-most-wide"
  | "phone-left";

export interface ViewProps {
  notification?: ReactNode;
  variant?: ViewVariant;
  className?: string;
  contentStyle?: CSSProperties;
}

export const ViewProvider = React.createContext<ViewVariant>("full-width");

const ViewWrapper = styled.div`
  background-color: ${constants.colorPanelBg};
  padding: 0 120px;

  @media (max-width: 1140px) {
    background-color: ${constants.colorBg};
  }
  ${media.tablet`
  padding: 0 40px;
`}
  ${media.phone`
  padding: 0 36px;
`}
`;

const Content = styled.div`
  box-sizing: content-box;
  margin: 44px auto;
  border: 1px solid rgba(216, 216, 216, 0.3);
  box-shadow: 0px 6px 12px rgb(180 190 207 / 15%);
  border-radius: 12px;
  background: #fff;
  padding: 88px 0 68px 0;

  @media (max-width: 1140px) {
    border: none;
    box-shadow: none;
    margin: 0 auto;
  }

  ${media.phone`
		padding: 80px 0 61px 0;
  `}

  > * {
    box-sizing: border-box;
  }
`;

const Wrap = styled.div<{ variant: ViewVariant }>`
  ${Content} {
    max-width: ${getViewWidth};
    text-align: ${props => (props.variant === "blank" ? "left" : "center")};

    ${({ variant }) => variant === "phone-left" && media.phone`text-align: left;`}
  }
`;

const View: React.FC<ViewProps> = ({ notification, variant = "full-width", className, children, contentStyle }) => {
  return (
    <ViewWrapper>
      <ViewProvider.Provider value={variant}>
        <Wrap variant={variant} className={className}>
          {notification}
          <Content style={contentStyle}>{children}</Content>
        </Wrap>
      </ViewProvider.Provider>
    </ViewWrapper>
  );
};

export function getViewWidth({ variant }: { variant: ViewVariant }): string {
  switch (variant) {
    case "form-middle":
      return `${constants.formMiddleWidth}px`;
    case "form-narrow":
      return `${constants.formNarrowWidth}px`;
    case "form-wide":
      return `${constants.formWideWidth}px`;
    case "form-most-wide":
      return `${constants.formMostWideWidth}px`;
    default:
      return `${constants.maxWidthMainContent}px`;
  }
}

export default View;
