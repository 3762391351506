import { ApolloProvider } from "@apollo/client";
import React from "react";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "normalize.css";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import setupGraphqlClient from "Services/setupGraphqlClient";
import "Services/i18n";

import App from "./App";
import Loader from "./Components/loader/Loader";
import config from "./config";

const graphqlClient = setupGraphqlClient({
  httpLink: {
    uri: config.graphqlUri,
  },
});

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || "",
});

ReactDOM.render(
  <ApolloProvider client={graphqlClient}>
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <App />
        </React.Suspense>
      </BrowserRouter>
    </IntercomProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);
