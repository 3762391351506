import React, { ReactNode } from "react";

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<unknown, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(_error: Error): { hasError: true } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
