import { useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

import DropdownMenu from "Components/dropdown-menu/DropdownMenu";
import Loader from "Components/loader/Loader";
import constants from "Gfx/constants";
import media from "Gfx/media";
import { logOutLocally } from "Services/authentication";

import { isIntegratorFlowUrl, isTransactionFlowUrl, isVerifyUrl } from "../../Services/integratorFlow";
import { useLogoutMutation } from "../../schema";
import { LocalizationDropdown } from "../localization-dropdown/LocallizationDropdown";

interface Props {
  userEmail?: string;
  isUserAuthenticated?: boolean;
}

const Navigation: React.FC<Props> = ({ isUserAuthenticated, userEmail }) => {
  const [t] = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const apolloClient = useApolloClient();

  const [logout, { loading }] = useLogoutMutation({
    update(cache) {
      // set user as logged out to redirect them to login view
      logOutLocally(cache);
    },
    async onCompleted() {
      // clear all stored data - triggers new network requests
      await apolloClient.resetStore();
    },
  });

  const isIntegratorFlow = isVerifyUrl() || isTransactionFlowUrl() || isIntegratorFlowUrl();

  return (
    <NavigationWrap>
      <DesktopMenu>
        {!isIntegratorFlow && isUserAuthenticated && renderMainMenu()}
        <LocalizationDropdown />

        {!isIntegratorFlow && isUserAuthenticated && (
          <DropdownMenu
            isVisible={isVisible}
            onToggle={setIsVisible}
            button={<ProfileButtonLetter>{userEmail ? userEmail.charAt(0) : "?"}</ProfileButtonLetter>}
          >
            {renderProfileMenu()}
            {renderLogoutMenu()}
          </DropdownMenu>
        )}
      </DesktopMenu>
      <MobileMenu>
        <LocalizationDropdown />
        {!isIntegratorFlow && isUserAuthenticated && (
          <DropdownMenu
            isVisible={isVisible}
            onToggle={setIsVisible}
            button={
              <MenuButton isVisible={isVisible}>
                <div className="mobileNav" />
                <div className="mobileNav" />
                <div className="mobileNav" />
              </MenuButton>
            }
          >
            {renderMainMenu()} {renderProfileMenu()} {renderLogoutMenu()}
          </DropdownMenu>
        )}
      </MobileMenu>
    </NavigationWrap>
  );

  function renderMainMenu() {
    return (
      <>
        <NavigationLink to="/verifications" exact>
          {t("Verifications")}
        </NavigationLink>
        <NavigationLink to="/platforms">{t("Platforms")}</NavigationLink>
      </>
    );
  }

  function renderProfileMenu() {
    return <NavigationLink to="/profile">{t("My Profile")}</NavigationLink>;
  }

  function renderLogoutMenu() {
    return (
      <LogoutButton onClick={handleLogout} disabled={loading}>
        <FancyLoader isVisible={loading} />
        {t("Log out")}
      </LogoutButton>
    );
  }

  function handleLogout(e: React.MouseEvent) {
    e.stopPropagation();

    logout().catch(() => {
      // ignore
    });
  }
};

const NavigationWrap = styled.nav`
  display: flex;
  flex: 1;
  align-items: center;
`;

const NavigationLink = styled(NavLink)``;

const DesktopMenu = styled.div`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: auto;

  li {
    max-height: 25px;
  }

  ${NavigationLink} {
    font-size: 16px;
    color: #37383d;
    text-decoration: none;

    &.active {
      font-weight: 600;
    }
  }

  > ${NavigationLink} {
    margin: 0 20px;
    font-weight: 600;

    &:hover,
    &.active {
      text-decoration: underline;
    }
  }

  ${media.phone`
    display: none;
  `}
`;

const MobileMenu = styled.div`
  display: none;
  text-align: right;
  margin-left: auto;

  a {
    text-decoration: none;
    color: ${constants.colorPrimary};

    &.active {
      font-weight: 600;
    }
  }

  ${media.phone`
		display: flex;

  `}
`;

const MenuButton = styled.div<{ isVisible: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
  .mobileNav {
    height: 0.15rem;
    border-radius: 10px;
    background-color: black;
    transform-origin: 1px;
    transition: all 0.3s linear;
    ${({ isVisible }) =>
      isVisible &&
      `
			&:nth-child(1) {
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
		}
    &:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
      top: 39px;
      left: 8px;
    }
	`}
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  position: relative;
`;

const FancyLoader = styled(Loader)`
  height: 30px;
  position: absolute;
  top: 5px;

  .sk-circle {
    margin: 0;
    height: 30px;
    width: 30px;
  }
`;

const ProfileButtonLetter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 100%;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  background: ${constants.colorSecondary};
`;

export default Navigation;
