import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

import Link, { LinkProps, LinkVariant } from "Components/link/Link";
import constants from "Gfx/constants";

export interface BackButtonProps {
  onClick?: () => void;
  to?: LinkProps["to"];
  replace?: LinkProps["replace"];
  variant?: LinkVariant;
  noArrow?: boolean;
  margin?: string;
}

const Arrow = styled.span`
  display: inline-block;
  margin-right: 4px;

  &::after {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    border-top: 1.5px solid ${constants.colorSecondary};
    border-right: 1.5px solid ${constants.colorSecondary};
    transform: translateY(-1px) rotate(-135deg);
  }
`;

const BackButton: React.FC<BackButtonProps> = ({ onClick, variant, noArrow, children, to, replace, margin }) => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <Link
      onClick={onClick || (to ? undefined : () => history.goBack())}
      to={to}
      replace={replace}
      variant={variant}
      margin={margin}
    >
      {!noArrow && <Arrow />}
      {children || t("Back")}
    </Link>
  );
};

export default BackButton;
