export interface ClientConfigEntry {
  entryKey: string;
  entryValue: string;
  entryType: string;
}

export interface ClientConfig {
  verificationNotificationMessage: string;
  verificationNotificationEnabled: boolean;
  identityNotificationMessage: string;
  identityNotificationEnabled: boolean;
  identityButtonDisabled: boolean;
  phoneNotificationMessage: string;
  phoneNotificationEnabled: boolean;
  phoneButtonDisabled: boolean;
  [key: string]: any;
}

export function parseClientConfig(data: ClientConfigEntry[] | undefined): ClientConfig {
  const config = {} as ClientConfig;

  if (!data || data.length < 1 || !data[0]) {
    return config;
  }

  for (let i = 0; i < data.length; i++) {
    const row = data[i];

    const entryValue: boolean | string = row.entryType === "boolean" ? !!+row.entryValue : row.entryValue;

    config[row.entryKey] = entryValue;
  }

  return config;
}
