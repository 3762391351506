import { MouseEventHandler } from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components/macro";

import constants from "Gfx/constants";
import media from "Gfx/media";

export interface ButtonProps {
  to?: LinkProps["to"];
  href?: string;
  onClick?: MouseEventHandler;
  type?: "button" | "submit";
  disabled?: boolean;
  className?: string;
  target?: "_blank";
  isBorder?: boolean;
}

const BaseButton: React.FC<ButtonProps> = props => {
  if (props.to) {
    return <Link to={props.to} {...props} />;
  }

  const Component = props.href ? "a" : "button";
  const type = Component === "button" ? props.type || "button" : undefined;
  const rel = props.target === "_blank" ? "noopener noreferrer" : undefined;

  return <Component {...props} type={type} rel={rel} />;
};

export const Button = styled(BaseButton)<{
  variant?: "outline" | "greyOutline";
  isresponsive?: "true";
  margin?: string;
  padding?: string;
  noMarginTop?: boolean;
  isBorder?: boolean;
}>`
  display: block;
  width: fit-content;
  min-width: 115px;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => (padding ? padding : "6px 20px")};
  border-radius: 40px;
  border: ${({ isBorder }) => (isBorder ? `2px solid ${constants.colorSecondary}` : "none")};
  color: ${({ variant }) =>
    variant === "outline"
      ? constants.colorSecondary
      : variant === "greyOutline"
      ? constants.colorFieldLabelAlternative
      : constants.colorTextInverted};
  background-color: ${({ variant }) =>
    variant === "outline" || variant === "greyOutline" ? constants.colorBg : constants.colorSecondary};
  font-family: ${constants.fontFamilySourceSansPro};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  ${({ isresponsive }) =>
    isresponsive &&
    media.phone`
      width: 100%;
    `}

  & + & {
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "0" : "20px")};
  }

  :disabled {
    border-color: ${constants.colorBgDisabled};
    color: ${props => (props.variant === "outline" ? constants.colorBgDisabled : constants.colorTextInverted)};
    background: ${props => (props.variant === "outline" ? constants.colorBg : constants.colorBgDisabled)};
  }
`;

export default Button;
