import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import VerifyOnceHeaderIcon from "Components/icons/VerifyOnceHeaderIcon";
import { getFlowPath, isIntegratorFlowUrl, isTransactionFlowUrl } from "Services/integratorFlow";

const LogoWrap = styled.div`
  width: 90px;
  height: 55px;
`;

interface LogoProps {
  isLoggedIn: boolean;
}

interface LocationState {
  transactionId?: string;
  transactionEmail?: string;
  skipLogin?: boolean;
}

const Logo: React.FC<LogoProps> = ({ isLoggedIn }) => {
  const [locationState, setLocationState] = useState<LocationState>({});
  const location = useLocation<LocationState>();

  useEffect(() => {
    if (location.state) {
      setLocationState({ ...locationState, ...location.state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getLogoLinkRedirect = (isUserAuthenticated: boolean) => {
    if (isUserAuthenticated) {
      if (isTransactionFlowUrl()) {
        return getFlowPath("/");
      }

      if (isIntegratorFlowUrl()) {
        return { pathname: "/platforms", state: locationState };
      }
      return "/";
    } else {
      if (isTransactionFlowUrl()) {
        if (locationState) {
          if (locationState.skipLogin) {
            return { pathname: "/transaction/verify-email", state: locationState };
          }

          if (locationState.transactionId) {
            return { pathname: "/transaction/login", state: locationState };
          }
        }
        return getFlowPath("/");
      } else {
        return "/";
      }
    }
  };

  return (
    <LogoWrap>
      <Link to={getLogoLinkRedirect(isLoggedIn)}>
        <VerifyOnceHeaderIcon />
      </Link>
    </LogoWrap>
  );
};

export default Logo;
