import React from "react";

const VerifyOnceHeaderIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ width = 88, height = 51, ...rest }) => (
  <svg width={width} height={height} {...rest} viewBox="0 0 88 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.174 7.2811L8.984 19.3941L3.946 7.2811H0L6.95 23.6172H10.866L17.816 7.2811H14.174Z" fill="#231F20" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.186 16.6728H34.904C34.944 16.1829 34.964 15.806 34.968 15.5439C34.968 13.8708 34.618 12.3972 33.92 11.1232C33.222 9.84924 32.25 8.85947 31.006 8.15595C29.762 7.45244 28.35 7.09967 26.772 7.09967C25.172 7.09967 23.736 7.45647 22.462 8.17007C21.186 8.88366 20.19 9.87746 19.472 11.1514C18.752 12.4254 18.394 13.8587 18.394 15.4492C18.394 17.0598 18.764 18.5031 19.502 19.7771C20.242 21.0531 21.288 22.0469 22.644 22.7605C23.998 23.4741 25.568 23.8309 27.348 23.8309C28.744 23.8309 30 23.6071 31.112 23.1576C32.224 22.7101 33.146 22.0469 33.874 21.17L31.84 18.8135C30.686 19.9968 29.218 20.5875 27.438 20.5875C26.042 20.5875 24.872 20.2367 23.932 19.5332C22.99 18.8297 22.408 17.8762 22.186 16.6728ZM22.156 14.1328C22.338 12.9294 22.848 11.9658 23.688 11.2422C24.528 10.5185 25.556 10.1556 26.768 10.1556C28.002 10.1556 29.034 10.5225 29.864 11.2563C30.694 11.992 31.19 12.9496 31.352 14.1328H22.156Z"
      fill="#231F20"
    />
    <path
      d="M47.37 7.09766V10.7382C47.046 10.6777 46.752 10.6455 46.49 10.6455C45.012 10.6455 43.86 11.0789 43.03 11.9457C42.2 12.8125 41.786 14.0623 41.786 15.6931V23.6172H37.992V7.28109H41.604V9.66781C42.696 7.95438 44.618 7.09766 47.37 7.09766Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.072 2.29399C50.072 2.94711 50.304 3.4934 50.77 3.93083C51.236 4.37028 51.812 4.58798 52.5 4.59C53.208 4.59 53.79 4.3602 54.246 3.90059C54.7 3.44099 54.928 2.87656 54.928 2.20328C54.928 1.57032 54.696 1.04419 54.23 0.626917C53.764 0.209644 53.188 0 52.5 0C51.812 0 51.236 0.219723 50.77 0.657154C50.304 1.0966 50.072 1.64087 50.072 2.29399ZM54.382 7.28111H50.588V23.6172H54.382V7.28111Z"
      fill="#231F20"
    />
    <path
      d="M63.24 6.42439V7.40407H67.702V10.4621H63.362V23.6152H59.568V10.4621H56.898V7.40407H59.568V6.36392C59.568 4.61016 60.074 3.22933 61.086 2.21941C62.098 1.20949 63.524 0.705536 65.366 0.705536C66.822 0.705536 67.966 1.00186 68.796 1.59249L67.734 4.46905C67.086 4.01953 66.388 3.79577 65.64 3.79577C64.038 3.79376 63.24 4.67063 63.24 6.42439Z"
      fill="#231F20"
    />
    <path
      d="M87.882 7.2811L80.294 24.9315C79.586 26.7054 78.726 27.9552 77.714 28.6789C76.702 29.4026 75.478 29.7654 74.042 29.7654C73.232 29.7654 72.434 29.6324 71.644 29.3683C70.854 29.1022 70.208 28.7354 69.702 28.2677L71.22 25.4536C71.584 25.8003 72.014 26.0765 72.51 26.2801C73.006 26.4837 73.506 26.5865 74.012 26.5865C74.68 26.5865 75.23 26.4132 75.666 26.0664C76.102 25.7197 76.502 25.1371 76.866 24.3228L77.138 23.6797L70.066 7.28311H74.012L79.112 19.3679L84.242 7.28311H87.882V7.2811Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.114 46.9119C1.856 48.194 2.888 49.1938 4.212 49.9115C5.536 50.6291 7.02 50.9879 8.67 50.9879C10.338 50.9879 11.832 50.6291 13.156 49.9115C14.478 49.1938 15.51 48.194 16.254 46.9119C16.996 45.6299 17.368 44.1785 17.368 42.5578C17.368 40.9391 16.996 39.4917 16.254 38.2198C15.512 36.9498 14.478 35.954 13.156 35.2364C11.834 34.5187 10.338 34.1599 8.67 34.1599C7.02 34.1599 5.536 34.5187 4.212 35.2364C2.89 35.954 1.856 36.9478 1.114 38.2198C0.37 39.4917 0 40.9371 0 42.5578C0 44.1785 0.37 45.6299 1.114 46.9119ZM13.524 42.5578C13.524 44.0958 13.066 45.3376 12.15 46.281C11.234 47.2244 10.074 47.6961 8.67 47.6961C7.264 47.6961 6.11 47.2244 5.204 46.281C4.298 45.3376 3.846 44.0958 3.846 42.5578C3.846 41.0197 4.298 39.778 5.204 38.8346C6.11 37.8912 7.266 37.4195 8.67 37.4195C10.074 37.4195 11.234 37.8912 12.15 38.8346C13.066 39.778 13.524 41.0197 13.524 42.5578Z"
      fill="#231F20"
    />
    <path
      d="M33.99 35.9721C35.232 37.1836 35.852 38.9777 35.852 41.3564V50.7722H32.036V41.8482C32.036 40.413 31.7 39.3305 31.028 38.6028C30.356 37.8751 29.4 37.5102 28.158 37.5102C26.754 37.5102 25.644 37.9355 24.83 38.7862C24.016 39.6369 23.608 40.8625 23.608 42.463V50.7702H19.796V34.3393H23.428V36.462C24.058 35.704 24.852 35.1295 25.81 34.7385C26.766 34.3494 27.844 34.1539 29.046 34.1539C31.1 34.1579 32.748 34.7626 33.99 35.9721Z"
      fill="#231F20"
    />
    <path
      d="M42.86 49.9115C41.516 49.1938 40.468 48.194 39.716 46.9119C38.962 45.6299 38.586 44.1785 38.586 42.5578C38.586 40.9371 38.962 39.4917 39.716 38.2198C40.468 36.9478 41.512 35.954 42.846 35.2364C44.178 34.5187 45.7 34.1599 47.41 34.1599C49.018 34.1599 50.426 34.4885 51.638 35.1436C52.848 35.8008 53.76 36.7442 54.37 37.9738L51.44 39.6973C50.972 38.9394 50.386 38.3689 49.684 37.99C48.982 37.611 48.214 37.4195 47.378 37.4195C45.954 37.4195 44.774 37.8871 43.838 38.8205C42.902 39.7538 42.434 40.9996 42.434 42.5598C42.434 44.118 42.896 45.3638 43.824 46.2971C44.75 47.2304 45.936 47.6981 47.38 47.6981C48.214 47.6981 48.984 47.5086 49.686 47.1296C50.388 46.7507 50.972 46.1802 51.442 45.4222L54.372 47.1458C53.74 48.3774 52.82 49.3249 51.61 49.9921C50.398 50.6593 49 50.9919 47.412 50.9919C45.72 50.9879 44.204 50.6291 42.86 49.9115Z"
      fill="#231F20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.586 43.7874H72.376C72.416 43.2956 72.436 42.9166 72.438 42.6485C72.438 40.9673 72.086 39.4857 71.384 38.2036C70.682 36.9216 69.706 35.9258 68.454 35.2182C67.202 34.5107 65.784 34.1559 64.196 34.1559C62.588 34.1559 61.144 34.5147 59.862 35.2323C58.578 35.95 57.576 36.9498 56.854 38.2319C56.13 39.5159 55.77 40.9552 55.77 42.5558C55.77 44.1765 56.142 45.6279 56.884 46.9099C57.626 48.192 58.68 49.1918 60.044 49.9094C61.408 50.6271 62.984 50.9859 64.776 50.9859C66.18 50.9859 67.44 50.7601 68.56 50.3086C69.68 49.857 70.606 49.1918 71.338 48.3089L69.294 45.9403C68.134 47.1296 66.658 47.7243 64.868 47.7243C63.464 47.7243 62.288 47.3715 61.342 46.664C60.394 45.9564 59.81 44.9969 59.586 43.7874ZM59.554 41.2334C59.738 40.0239 60.252 39.0543 61.096 38.3266C61.94 37.5989 62.974 37.234 64.194 37.234C65.436 37.234 66.474 37.6029 67.308 38.3407C68.142 39.0805 68.64 40.0441 68.804 41.2334H59.554Z"
      fill="#231F20"
    />
  </svg>
);

export default VerifyOnceHeaderIcon;
