import React from "react";
import { Redirect } from "react-router";
import { Route, Switch } from "react-router-dom";

const LoginView = React.lazy(() => import("./LoginView"));
const TransactionLoginView = React.lazy(() => import("./TransactionLoginView"));
const SignupView = React.lazy(() => import("./SignupView"));
const ForgotPasswordView = React.lazy(() => import("./ForgotPasswordView"));
const VerifyEmailView = React.lazy(() => import("./VerifyEmailView"));
const ConfirmEmailView = React.lazy(() => import("./ConfirmEmailView"));

const PublicView: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact={true} component={LoginView} />
      <Route path="/signup" exact={true} component={SignupView} />
      <Route path="/transaction/login" exact={true} component={TransactionLoginView} />
      <Route path="/logout" exact={true} render={() => <Redirect to="/" />} />
      <Route path="/forgot-password" exact={true} component={ForgotPasswordView} />
      <Route path={["/verify-email", "/transaction/verify-email"]} exact={true} component={VerifyEmailView} />
      <Route path="/confirm-email/:id" exact={true} component={ConfirmEmailView} />

      <Redirect to="/" />
    </Switch>
  );
};

export default PublicView;
