import { Placement } from "@popperjs/core";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled, { CSSProperties } from "styled-components";

import constants from "Gfx/constants";

export interface DropdownMenuProps {
  button: React.ReactElement;
  isVisible: boolean;
  onToggle: (isVisible: boolean) => void;
  menuGap?: number;
  menuWidth?: string;
  clickableStyle?: CSSProperties;
  itemWrapperStyle?: CSSProperties;
  placement?: Placement;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  button: Clickable,
  isVisible,
  children,
  onToggle,
  menuGap,
  menuWidth,
  clickableStyle,
  itemWrapperStyle,
  placement,
}) => {
  /**
   * Note: the usePopper hook intentionally takes the DOM node, not refs,
   * in order to be able to update when the nodes change.
   * https://popper.js.org/react-popper/v2/
   */
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(anchorElement, popperElement, {
    modifiers: [
      { name: "preventOverflow", enabled: true, options: { padding: 10 } },
      { name: "offset", enabled: true, options: { offset: [0, menuGap || 5] } },
    ],
    placement: placement || "bottom-end",
  });

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    function handleClick(event: MouseEvent) {
      const target = event.target as HTMLElement;

      if (!anchorElement || !target || target.closest(anchorElement.localName)) {
        return;
      }

      onToggle(false);
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isVisible, onToggle, anchorElement]);

  return (
    <Wrapper>
      <Button onClick={() => onToggle(!isVisible)} ref={setAnchorElement} style={clickableStyle}>
        {Clickable}
      </Button>
      {isVisible && (
        <ItemsWrapper
          menuWidth={menuWidth}
          ref={setPopperElement}
          style={{ ...styles.popper, ...itemWrapperStyle }}
          {...attributes.popper}
        >
          {children}
        </ItemsWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: grid;
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const ItemsWrapper = styled.div<{ menuWidth?: string }>`
  position: absolute;
  padding: 5px 0;
  width: ${({ menuWidth }) => menuWidth || "170px"};
  background-color: #fff;
  border: 1px solid #c4d0da;
  box-shadow: ${constants.boxShadowModal};
  border-radius: ${constants.borderRadius};
  z-index: 100;

  > * {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: ${constants.colorPanelBg};
    }
  }
`;

export default DropdownMenu;
