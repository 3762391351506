import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import styled from "styled-components/macro";

import DropdownMenu from "../dropdown-menu/DropdownMenu";

import { ReactComponent as GreenFilledCircularCheckIcon } from "./assets/green-filled-circular-check-icon.svg";
import { ReactComponent as LocalizationGlobeIcon } from "./assets/localization-globe-icon.svg";

interface MappedLanguage {
  code: string;
  name: string;
  displayCode: string;
}

export const LocalizationDropdown: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [languages, setLanguages] = useState<MappedLanguage[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.languages[0]);
  const { update } = useIntercom();

  const languageFullNames: Record<string, { nativeName: string; translationKey: string; englishName: string }> =
    useMemo(
      () => ({
        en: {
          nativeName: "English",
          translationKey: "en",
          englishName: "English",
        },
        es: {
          nativeName: "Español",
          translationKey: "es",
          englishName: "Spanish",
        },
        ar: {
          nativeName: "عربي",
          translationKey: "ar",
          englishName: "Arabic",
        },
        ru: {
          nativeName: "русский",
          translationKey: "ru",
          englishName: "Russian",
        },
        ach: {
          nativeName: "Crowdin translation mode",
          translationKey: "ach",
          englishName: "Crowdin translation mode",
        },
      }),
      [],
    );

  useEffect(() => {
    const ENABLED_LANGUAGES = process.env.REACT_APP_TRANSLATION_LANGUAGES?.split(",");

    if (ENABLED_LANGUAGES) {
      const mappedLanguages = ENABLED_LANGUAGES.map((lngCode: string): MappedLanguage => {
        return {
          code: lngCode,
          displayCode: lngCode[0] + lngCode[1],
          name: t(languageFullNames[lngCode].translationKey),
        };
      });

      setLanguages(mappedLanguages);
    }
  }, [t, languageFullNames]);

  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode).then(() => {
      localStorage.setItem("language", languageCode);
      setSelectedLanguage(languageCode);
      update({ languageOverride: languageCode });
    });
  };

  return (
    <Container>
      <SelectedLangLabel>{languageFullNames[i18n.languages[0]].translationKey.toUpperCase()}</SelectedLangLabel>
      <DropdownContainer>
        <DropdownMenu
          clickableStyle={{ display: "flex" }}
          button={
            <IconContainer>
              <LocalizationGlobeIcon />
            </IconContainer>
          }
          isVisible={isDropdownOpen}
          onToggle={setIsDropdownOpen}
        >
          {languages.map((lng: MappedLanguage) => (
            <LanguageItem
              key={lng.code}
              style={{ backgroundColor: selectedLanguage === lng.code ? "rgba(218, 247, 241, .5)" : "" }}
              onClick={() => changeLanguage(lng.code)}
            >
              {lng.name} {selectedLanguage === lng.code && <GreenFilledCircularCheckIcon />}
            </LanguageItem>
          ))}
        </DropdownMenu>
      </DropdownContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
`;

const IconContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const SelectedLangLabel = styled.p`
  display: flex;
  margin: 0 9px 0 0;
`;

const DropdownContainer = styled.div`
  display: flex;
`;

const LanguageItem = styled.div`
  display: flex;
  justify-content: space-between;
`;
