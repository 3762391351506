export function isTransactionFlowUrl(): boolean {
  return window.location.pathname.startsWith("/transaction");
}

export function isVerifyUrl(): boolean {
  return window.location.pathname.startsWith("/verify/");
}

export function isPaymentStatusUrl(): boolean {
  return window.location.pathname.startsWith("/payment-status");
}

export function isIntegratorFlowUrl(): boolean {
  const regex = /integrator\//gi;

  return regex.test(window.location.pathname);
}

export function getFlowPath(path = ""): string {
  const match = window.location.pathname.match(/(\/integrator\/[^/]+)/);

  if (match) {
    return `${match[0]}${path}`;
  }

  return `${isTransactionFlowUrl() ? "/transaction" : "/verifications"}${path}`.replace(/\/$/, "");
}
