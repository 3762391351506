import { createGlobalStyle } from "styled-components/macro";

import constants from "./constants";

export default createGlobalStyle`
	html {
		box-sizing: border-box;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	body {
		font-family: ${constants.fontFamilySourceSansPro};
		font-size: 18px;
		color: ${constants.colorTextPrimary};
		background: ${constants.colorPanelBg};

		@media (max-width: 1140px) {
			background: ${constants.colorBg}; 
    }
	}

	h1 {
		font-size: 48px;
		letter-spacing: 0.75px;
		line-height: 60px;
	}

	h2 {
		font-size: 30px;
		margin: 20px 0 20px 0;
		line-height: 38px;
	}

	h3 {
		font-size: 24px;
		line-height: 31px;
		letter-spacing: 0.38px;
	}

	p {
		margin: 0 0 1em;
	}
`;
