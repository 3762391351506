import { ApolloClient, ApolloLink, HttpOptions, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { v4 as uuid } from "uuid";

export interface GraphqlClientOptions {
  httpLink: HttpOptions;
}

function cooldownUntil({ resendCooldownSec }: { resendCooldownSec?: number }) {
  if (!resendCooldownSec) {
    return null;
  }

  const cooldownUntil = new Date();
  cooldownUntil.setSeconds(cooldownUntil.getSeconds() + resendCooldownSec);

  return cooldownUntil;
}

export default function setupGraphqlClient(options: GraphqlClientOptions): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: ApolloLink.from([
      setupHeadersLink(),
      setupErrorLink(),
      createUploadLink(options.httpLink) as unknown as ApolloLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            viewer: {
              // viewer should behave like singleton, but it value depends on integratorId argument
              // so we store integratorId with the viewer and compare it during reading
              // if integrator IDs do not match, we return undefined which trigger network fetch
              read(existing, { args }) {
                const integratorId = args?.integratorId || null;

                if (!existing || existing.integratorId !== integratorId) {
                  return;
                }

                return existing;
              },
              merge(existing, incoming, { args }) {
                return {
                  ...incoming,
                  integratorId: args?.integratorId || null,
                };
              },
              keyArgs: false,
            },
          },
        },
        User: {
          fields: {
            documentUploads: {
              merge: false,
            },
          },
        },
        AuthenticationStatus: {
          keyFields: [],
        },
        EmailVerification: {
          keyFields: [],
        },
        UserSettings: {
          keyFields: [],
        },
        PhoneVerificationAttempt: {
          keyFields: [],
        },
        Transaction: {
          fields: {
            info: {
              merge: true,
            },
          },
        },
      },
    }),
    resolvers: {
      PhoneVerificationAttempt: {
        cooldownUntil,
      },
      EmailVerification: {
        cooldownUntil,
      },
    },
    connectToDevTools: true,
  });
}

function setupHeadersLink() {
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-request-id": uuid(),
        "x-client-path": window.location.href,
        "x-client-version": process.env.REACT_APP_VERSION,
      },
    };
  });
}

function setupErrorLink() {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    }

    if (networkError) {
      console.warn(`[Network error]: ${networkError}`);
    }
  });
}
