import { ApolloCache } from "@apollo/client";

export function logOutLocally<T>(cache: ApolloCache<T>): void {
  cache.modify({
    id: cache.identify({ __typename: "AuthenticationStatus" }),
    fields: {
      isLoggedIn() {
        return false;
      },
    },
  });
}
