import React from "react";
import { useTranslation } from "react-i18next";

import BackButton from "Components/back-button/BackButton";
import Title from "Components/typography/Title";
import View from "Components/view/View";

const NotFoundView: React.FC = () => {
  const [t] = useTranslation();

  return (
    <View>
      <Title>{t("Not Found 404")}</Title>
      <BackButton />
    </View>
  );
};

export default NotFoundView;
