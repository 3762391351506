import { CSSObject, SimpleInterpolation } from "styled-components";
import { css } from "styled-components/macro";

interface Sizes {
  desktop: number;
  tablet: number;
  phone: number;
}

const sizes: Sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

type Args = [TemplateStringsArray | CSSObject, ...SimpleInterpolation[]];

function createMedia(maxWidth: number) {
  return (...args: Args) =>
    css`
      @media (max-width: ${maxWidth}px) {
        ${css(...args)}
      }
    `;
}

const media = {
  phone: createMedia(sizes.phone),
  tablet: createMedia(sizes.tablet),
  desktop: createMedia(sizes.desktop),
};

export default media;
