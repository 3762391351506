import { MouseEventHandler, ReactNode } from "react";
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from "react-router-dom";
import styled, { css } from "styled-components/macro";

import Button from "Components/button/Button";
import constants from "Gfx/constants";

export type LinkVariant = "full-width" | "full-width-top" | "full-width-left" | "secondary";

export interface LinkProps {
  to?: ReactRouterLinkProps["to"];
  replace?: ReactRouterLinkProps["replace"];
  href?: string;
  onClick?: MouseEventHandler;
  className?: string;
  target?: "_blank";
  children: ReactNode;
}

const BaseLink: React.FC<LinkProps> = ({ to, children, ...rest }) => {
  if (to) {
    return (
      <ReactRouterLink to={to} {...rest}>
        {children}
      </ReactRouterLink>
    );
  }

  const rel = rest.target === "_blank" ? "noopener noreferrer" : undefined;

  return (
    <a rel={rel} {...rest}>
      {children}
    </a>
  );
};

const Link = styled(BaseLink)<{ variant?: LinkVariant; margin?: string }>`
  cursor: pointer;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ variant }) =>
    variant === "secondary"
      ? css`
          color: currentColor;

          &:hover {
            text-decoration: none;
          }
        `
      : css`
          color: ${constants.colorSecondary};
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        `}

  ${({ variant }) =>
    variant &&
    ["full-width", "full-width-top", "full-width-left"].includes(variant) &&
    css`
      display: block;
      width: fit-content;
      text-align: center;
      margin: ${variant === "full-width-top" ? "60px 0 20px" : "auto"};

      & + &,
      ${Button} + & {
        margin-top: 20px;
      }
    `}


  ${({ variant }) =>
    variant === "full-width-left" &&
    css`
      margin: 0;
    `}
`;

export default Link;
