export interface Config {
  graphqlUri: string;
  viewLoadTimeout: number;
  simulatedLatency: number;
}

// extract configuration from the .env file that populates environment variables
const config: Config = {
  graphqlUri: process.env.REACT_APP_GRAPHQL_URI || "/graphql",
  simulatedLatency: process.env.REACT_APP_SIMULATED_LATENCY ? parseInt(process.env.REACT_APP_SIMULATED_LATENCY, 10) : 0,
  viewLoadTimeout: process.env.REACT_APP_VIEW_LOAD_TIMEOUT
    ? parseInt(process.env.REACT_APP_VIEW_LOAD_TIMEOUT, 10)
    : 5000,
};

export default config;
