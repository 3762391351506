import i18n from "i18next";
import LngDetector from "i18next-browser-languagedetector";
import Fetch, { FetchOptions } from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";

import { LocaleEnum } from "../schema";

const REMOTE_URL = `${process.env.REACT_APP_CROWDIN_CDN_URL}`;
const LOCAL_URL = "/locales";

const detectionOptions = {
  // order and from where user language should be detected
  order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path"],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

const backendOptions: Partial<FetchOptions> = {
  // load translations from crowdin CDN
  loadPath: `${process.env.REACT_APP_CROWDIN_CDN_ENABLED === "true" ? REMOTE_URL : LOCAL_URL}/{{lng}}/{{ns}}.json`,
  // init option for fetch
  requestOptions: {
    mode: "cors",
    credentials: "same-origin",
    cache: "default",
    headers: {
      "Content-Type": "application/json",
    },
  },
  // custom fetch function to support fallback url
  fetch: async (url: any, options: any) => {
    // try loading from default url
    const res = await fetch(url, options);
    // return result if success or no fallback url is configured
    if (res.ok || process.env.REACT_APP_CROWDIN_CDN_ENABLED === "false") {
      return res;
    }
    // use fallback url to try load translations from local server
    // url.replace is used to reuse correct language code and namespace
    const fallbackUrl = url.toString().replace(REMOTE_URL, LOCAL_URL);

    console.warn(`Failed to load translations from "${url.toString()}", falling back to "${fallbackUrl}"`);
    return fetch(fallbackUrl, options);
  },
};

const currencySymbols: Record<string, string> = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  DAG: "DAG",
};

i18n
  .use(Fetch)
  .use(LngDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    load: "languageOnly",
    supportedLngs: process.env.REACT_APP_TRANSLATION_LANGUAGES?.split(","),
    defaultNS: "translation",
    ns: ["translation", "country_translations"],

    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,

    // fallback to keys
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: backendOptions,
    detection: detectionOptions,
  })
  .catch(err => console.error("Error occurred while trying to load translations", err));

export function getLocaleParam(): LocaleEnum | undefined {
  const lng = i18n.language;

  return Object.values(LocaleEnum).find(v => v === lng);
}

export function getCurrencySymbol(currency: string): string {
  return currency in currencySymbols ? currencySymbols[currency] : currency;
}

export function humanReadableJoin(items: string[], lastSeparator: string): string {
  if (items.length <= 1) {
    return items[0] || "";
  }

  const firsts = items.slice(0, -1);

  return `${firsts.join(", ")} ${lastSeparator} ${items[items.length - 1]}`;
}

export function getReadableConsentText(consentText: string): string {
  switch (consentText) {
    case "identityVerification":
      return "Identity verification";

    case "addressVerification":
      return "Residential address";

    case "addressProof":
      return "Address verification";

    case "phoneVerification":
      return "Mobile verification";

    default:
      return "";
  }
}

export function getParsedIdType(documentType?: string | null): string {
  if (!documentType) {
    return "";
  }

  return documentType
    .split("_")
    .map(word => `${word.charAt(0)}${word.slice(1).toLowerCase()}`)
    .join(" ");
}

export default i18n;
